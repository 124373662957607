import React from "react"
import { ReactTypeformEmbed } from 'react-typeform-embed'
import SEO from "../components/seo"

const IndexPage = () => (
    <div className="IlludicFormWidget">
      <SEO title="home" keywords={[`illudic`, `application`, `react`]} />
      <ReactTypeformEmbed popup={false} url="https://briangyss.typeform.com/to/qu0gyY" />
    </div>
)

export default IndexPage
